.loader-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #F8F8F8AD;
}

.inner {
    display: table;
    margin: auto;
    padding-top: 50%;
    z-index: 9999;
}

.outer {
    margin: auto;
    width: 50%;
}